// Generated by Framer (1249b65)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./wJSVIFvln-0.js";

const cycleOrder = ["hDSDLNVNq", "UTQqip3Y9", "ukC5r8cXM"];

const variantClassNames = {hDSDLNVNq: "framer-v-9q3tgx", ukC5r8cXM: "framer-v-1b9euln", UTQqip3Y9: "framer-v-19usfl3"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const valuesByLocaleId = {NTHK784vL: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transformTemplate = (_, t) => `translateY(-50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "hDSDLNVNq", "Variant 2": "UTQqip3Y9", "Variant 3": "ukC5r8cXM"}

const getProps = ({height, id, title, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "hDSDLNVNq", VW8MVGAen: title ?? props.VW8MVGAen ?? "Design Studio Next Gen"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, VW8MVGAen, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "hDSDLNVNq", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter96gv4p = activeVariantCallback(async (...args) => {
setVariant("UTQqip3Y9")
})

const onMouseLeave1qfev7m = activeVariantCallback(async (...args) => {
setVariant("ukC5r8cXM")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-uZuF9", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-9q3tgx", className)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"hDSDLNVNq"} onMouseEnter={onMouseEnter96gv4p} ref={ref} style={{...style}} tabIndex={1} {...addPropertyOverrides({ukC5r8cXM: {"data-framer-name": "Variant 3"}, UTQqip3Y9: {"data-framer-name": "Variant 2", onMouseLeave: onMouseLeave1qfev7m}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7TGVhZ3VlIFNwYXJ0YW4tNzAw", "--framer-font-family": "\"League Spartan\", \"League Spartan Placeholder\", sans-serif", "--framer-font-weight": "700", "--framer-line-height": "100px", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}><motion.span style={{"--framer-font-size": "89.3333px"}}>Design Studio Next Gen</motion.span></motion.p></React.Fragment>} className={"framer-1rs58da"} data-framer-name={"Nadpis"} fonts={["GF;League Spartan-700"]} layoutDependency={layoutDependency} layoutId={"qD7lxBMks"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px"}} text={VW8MVGAen} transformTemplate={transformTemplate} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-uZuF9 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-uZuF9 .framer-1ok5bmo { display: block; }", ".framer-uZuF9 .framer-9q3tgx { height: 235px; overflow: hidden; position: relative; width: 639px; }", ".framer-uZuF9 .framer-1rs58da { -webkit-user-select: none; flex: none; height: auto; left: 0px; pointer-events: auto; position: absolute; right: 0px; top: 50%; user-select: none; white-space: pre-wrap; word-break: break-word; word-wrap: break-word; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 235
 * @framerIntrinsicWidth 639
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"UTQqip3Y9":{"layout":["fixed","fixed"]},"ukC5r8cXM":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"VW8MVGAen":"title"}
 * @framerImmutableVariables false
 */
const FramerwJSVIFvln: React.ComponentType<Props> = withCSS(Component, css, "framer-uZuF9") as typeof Component;
export default FramerwJSVIFvln;

FramerwJSVIFvln.displayName = "Nadpis";

FramerwJSVIFvln.defaultProps = {height: 235, width: 639};

addPropertyControls(FramerwJSVIFvln, {variant: {options: ["hDSDLNVNq", "UTQqip3Y9", "ukC5r8cXM"], optionTitles: ["Variant 1", "Variant 2", "Variant 3"], title: "Variant", type: ControlType.Enum}, VW8MVGAen: {defaultValue: "Design Studio Next Gen", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerwJSVIFvln, [{family: "League Spartan", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/wJSVIFvln:default", url: "https://fonts.gstatic.com/s/leaguespartan/v11/kJEnBuEW6A0lliaV_m88ja5Twtx8BWhtkDVmjZvMGYPpBMdcFguczA.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/leaguespartan/v11/kJEnBuEW6A0lliaV_m88ja5Twtx8BWhtkDVmjZvMGYPpBMdcFguczA.ttf", weight: "700"}])